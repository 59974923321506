import React, { useEffect, useState }/* , { useState }  */from "react";
import { ButtonContainer, OptionCheck, RewardsBucketFormMainDiv } from "../styles";
import { closeIcon, RewardsPointsManagementDoneButtonIcon } from "../../../utils/icons";
import { SearchDropDown } from "../../CreateEvent/style";
import Select from 'react-select';
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { BASE_URL } from "../../../utils/config";
import { UpdateCompanyRewardPoints as updateRewardUrl } from '../../../redux/constants/apiConstants';
import { toast } from "react-toastify";
import HoverButton from "../../common/HoverButton";

const getBucketCaps = (length, startIndex) => {
  let BucketPointCapsArray = Array.from({ length: length }, (v, i) => { 
    let points = (i + startIndex) * 500
    return {value: parseInt(points.toString(),10), label: points+" points"}
  });
  return BucketPointCapsArray;
}

const defaultBronze = {value: "2000", label: "2000 points"};
const defaultSilver = {value: "4000", label: "4000 points"};
const defaultGold = {value: "6000", label: "6000 points"};
const defaultPlatinum = {value: "8000", label: "8000 points"};

function ManageRewardsBucketCapsForm({setShowManageRewardsBucketCapsForm, companyRewardDetails, companyInfo,hideManageRewardsBucketCapsForm}) {
  const [dropdownArrowDirectionBronze, setDropdownArrowDirectionBronze] = useState(true);
  const [dropdownArrowDirectionSilver, setDropdownArrowDirectionSilver] = useState(true);
  const [dropdownArrowDirectionGold, setDropdownArrowDirectionGold] = useState(true);
  const [dropdownArrowDirectionPlatinum, setDropdownArrowDirectionPlatinum] = useState(true);

  const [bronzeData, setBronzeData] = useState(null);
  const [silverData, setSilverData] = useState(null);
  const [goldData, setGoldData] = useState(null);
  const [platinumData, setPlatinumData] = useState(null);

  // Radio button selected values
  const [bronzeSelected, setBronzeSelected] = useState(false);
  const [silverSelected, setSilverSelected] = useState(false);
  const [goldSelected, setGoldSelected] = useState(false);
  const [platinumSelected, setPlatinumSelected] = useState(false);

  // Dropdown selected values
  const [bronzeDropdownValue, setBronzeDropdownValue] = useState(null);
  const [silverDropdownValue, setSilverDropdownValue] = useState(null);
  const [goldDropdownValue, setGoldDropdownValue] = useState(null);
  const [platinumDropdownValue, setPlatinumDropdownValue] = useState(null);

  const DropdownIndicator = () => (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 10C12 10.3585 11.8559 10.7169 11.5684 10.9901L2.51663 19.5897C1.94083 20.1368 1.00725 20.1368 0.431681 19.5897C-0.143894 19.0429 -0.143894 18.1561 0.431681 17.609L8.44122 10L0.43196 2.39098C-0.143614 1.84394 -0.143614 0.957264 0.43196 0.410484C1.00753 -0.136826 1.94111 -0.136826 2.51691 0.410484L11.5687 9.00992C11.8563 9.28333 12 9.64172 12 10Z" fill="#005C87"/>
    </svg>
  );

  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "hsl(0,0%,70%)" : "hsl(0,0%,70%)",
      },
    }),
  };

  const updateCompanyRewardPoints = async () => {

    let dataTobeUpdated = {
      "company_id": companyInfo.id,
      "bronze": bronzeDropdownValue?bronzeDropdownValue.value:null,
      "silver": silverDropdownValue?silverDropdownValue.value:null,
      "gold": goldDropdownValue?goldDropdownValue.value:null,
      "finished": platinumDropdownValue?platinumDropdownValue.value:null,
    }
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url = BASE_URL+updateRewardUrl;

    try{
      await fetch(url, {
        method:"PUT",
        headers: {
          authtoken: AuthToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(dataTobeUpdated),
      }).then((res) => {
        res.json().then( (data) => {
          if(data.status === "failed"){
            toast.error(data.data.message);
          }else{
            toast.success(data.data[0]);
          }
          // history.push("/company/rewards-management");
          // setShowManageRewardsBucketCapsForm(false);
          hideManageRewardsBucketCapsForm();
          
          
        })
      })
    }
    catch(error){
      toast.error(error);
    }
  }
  
  useEffect(()=>{
    let bronzeValues, silverValues, goldValues, platinumValues;

    setBronzeDropdownValue({ "value": companyRewardDetails[0]?.tier_point, "label": companyRewardDetails[0]?.tier_point+' points'})
    setSilverDropdownValue({ "value": companyRewardDetails[1]?.tier_point, "label": companyRewardDetails[1]?.tier_point+' points'})
    setGoldDropdownValue({ "value": companyRewardDetails[2]?.tier_point, "label": companyRewardDetails[2]?.tier_point+' points'})
    setPlatinumDropdownValue({ "value": companyRewardDetails[3]?.tier_point, "label": companyRewardDetails[3]?.tier_point+' points'})
    
    bronzeValues = getBucketCaps(4, 2);
    silverValues = getBucketCaps(5, 6);
    goldValues = getBucketCaps(4, 12);
    platinumValues = getBucketCaps(5, 16);

    setBronzeData(bronzeValues);
    setSilverData(silverValues);
    setGoldData(goldValues);
    setPlatinumData(platinumValues);
  }, []);

  return (
    <RewardsBucketFormMainDiv>
      <div className="headerDiv">
        <HoverButton svgPath={closeIcon()} activeColor={"#007AB1"} fillOpacity={1} title={"Close"} onClick={()=>setShowManageRewardsBucketCapsForm(false)} />
        <div className="headerText">Manage Rewards Bucket Caps</div>
      </div>

      <div className="mainContainer">
        <div className="leftSection">
          <div className="stepsDiv">
            <div className="steps">
              1
            </div>
            <div className="stesText">
              Modify Rewards Caps
            </div>
          </div>
        </div>
        <div className="rightSection">
          <div className="headerSection">
            <div className="steps">
              1
            </div>
            <div className="headerText">
              Modify Values
            </div>
          </div>

          <div className="fieldSection">
            <div className="titleSection">
              <div className="left">
                <span className="mainText">Bronze Badge <span style={{color: '#F4AAA9'}}>*</span> </span>
                <span className="subText">(Min 1000 pts - Max 2,500 Pts)</span>
              </div>
              <div className="right">
                <OptionCheck isSelected={true}>
                  <input className='checkboxInput' type='checkbox' /* value={currentStepRewardsData.defaultRangePoint} */  onChange={(e)=> {setBronzeSelected(!bronzeSelected); e.target.checked? setBronzeDropdownValue(defaultBronze) :setBronzeDropdownValue({ "value": companyRewardDetails.bronze, "label": companyRewardDetails.bronze+' points'})}} checked={bronzeSelected} />
                </OptionCheck>
                <span className="defaultText">
                  Set Default
                </span>
              </div>
            </div>
            <div className="inputDiv">
              <SearchDropDown
                padding="10px"
                width="145px"
                height="40px"
                indicatorWidth="11px"
                valueContainerP="0px 10px"
                dropdownTextFont="rubik-medium !important"
                transform={ dropdownArrowDirectionBronze ? "rotate(0deg)" : "rotate(90deg)"}
                // pcolor={filterValue ? "#005C87" : "#005C87"}
                // pfont={filterValue ? "rubik-medium" : "rubik"}
                right="10px"
                onClick={()=>setDropdownArrowDirectionBronze(!dropdownArrowDirectionBronze)}
                onBlur={()=>setDropdownArrowDirectionBronze(true)}
                isReportDropdown={false}
                style={{width: "100%"}}
                isReward={true}
              >
                <Select
                  value={bronzeDropdownValue ? bronzeDropdownValue : null}
                  options={bronzeData}
                  onChange={(option) =>{
                    setBronzeSelected(false);
                    setBronzeDropdownValue(option);
                  }}
                  classNamePrefix={"menu-item"}
                  styles={customStyles}
                  components={{ DropdownIndicator: DropdownIndicator, IndicatorSeparator: () => null }}
                  onMenuOpen={this.toggleMenu}
                  onMenuClose={this.toggleMenu}
                  placeholder={"Select points goal"}
                  isSearchable={false}
                  // formatOptionLabel={filterValue}
                />
              </SearchDropDown>
            </div>
          </div>

          <div className="fieldSection">
            <div className="titleSection">
              <div className="left">
                <span className="mainText">Silver Badge <span style={{color: '#F4AAA9'}}>*</span> </span>
                <span className="subText">(Min 3,000 pts - Max 5,000 Pts)</span>
              </div>
              <div className="right">
                <OptionCheck isSelected={true}>
                  <input className='checkboxInput' type='checkbox' /* value={currentStepRewardsData.defaultRangePoint}*/ onChange={(e)=> {setSilverSelected(!silverSelected); e.target.checked? setSilverDropdownValue(defaultSilver) :setSilverDropdownValue({ "value": companyRewardDetails.silver, "label": companyRewardDetails.silver+' points'})}} checked={silverSelected} />
                </OptionCheck>
                <span className="defaultText">
                  Set Default
                </span>
              </div>
            </div>
            
            <div className="inputDiv">
              <SearchDropDown
                padding="10px"
                width="145px"
                height="40px"
                indicatorWidth="11px"
                valueContainerP="0px 10px"
                dropdownTextFont="rubik-medium !important"
                transform={ dropdownArrowDirectionSilver ? "rotate(0deg)" : "rotate(90deg)"}
                // pcolor={filterValue ? "#005C87" : "#005C87"}
                // pfont={filterValue ? "rubik-medium" : "rubik"}
                right="10px"
                onClick={()=>setDropdownArrowDirectionSilver(!dropdownArrowDirectionSilver)}
                onBlur={()=>setDropdownArrowDirectionSilver(true)}
                isReportDropdown={false}
                style={{width: "100%"}}
                isReward={true}
              >
                <Select
                  value={silverDropdownValue ? silverDropdownValue : null}
                  options={silverData}
                  onChange={(option) =>{
                    setSilverSelected(false);
                    setSilverDropdownValue(option);
                  }}
                  classNamePrefix={"menu-item"}
                  styles={customStyles}
                  components={{ DropdownIndicator: DropdownIndicator, IndicatorSeparator: () => null }}
                  onMenuOpen={this.toggleMenu}
                  onMenuClose={this.toggleMenu}
                  placeholder={"Select points goal"}
                  isSearchable={false}
                  // formatOptionLabel={filterValue}
                />
              </SearchDropDown>
            </div>
          </div>


          <div className="fieldSection">
            <div className="titleSection">
              <div className="left">
                <span className="mainText">Gold Badge <span style={{color: '#F4AAA9'}}>*</span> </span>
                <span className="subText">(Min 6,000 pts - Max 7,500 Pts)</span>
              </div>
              <div className="right">
                <OptionCheck isSelected={true}>
                  <input className='checkboxInput' type='checkbox' /* value={currentStepRewardsData.defaultRangePoint} */ onChange={(e)=> {setGoldSelected(!goldSelected); e.target.checked? setGoldDropdownValue(defaultGold) :setGoldDropdownValue({ "value": companyRewardDetails.gold, "label": companyRewardDetails.gold+' points'})}} checked={goldSelected} />
                </OptionCheck>
                <span className="defaultText">
                  Set Default
                </span>
              </div>
            </div>
            <div className="inputDiv">
              <SearchDropDown
                padding="10px"
                width="145px"
                height="40px"
                indicatorWidth="11px"
                valueContainerP="0px 10px"
                dropdownTextFont="rubik-medium !important"
                transform={ dropdownArrowDirectionGold ? "rotate(0deg)" : "rotate(90deg)"}
                // pcolor={filterValue ? "#005C87" : "#005C87"}
                // pfont={filterValue ? "rubik-medium" : "rubik"}
                right="10px"
                onClick={()=>setDropdownArrowDirectionGold(!dropdownArrowDirectionGold)}
                onBlur={()=>setDropdownArrowDirectionGold(true)}
                isReportDropdown={false}
                style={{width: "100%"}}
                isReward={true}
              >
                <Select
                  value={goldDropdownValue?goldDropdownValue:null}
                  options={goldData}
                  onChange={(option) =>{
                    setGoldSelected(false);
                    setGoldDropdownValue(option);
                  }}
                  classNamePrefix={"menu-item"}
                  styles={customStyles}
                  components={{ DropdownIndicator: DropdownIndicator, IndicatorSeparator: () => null }}
                  onMenuOpen={this.toggleMenu}
                  onMenuClose={this.toggleMenu}
                  placeholder={"Select points goal"}
                  isSearchable={false}
                  // formatOptionLabel={filterValue}
                />
              </SearchDropDown>
            </div>
          </div>

          <div className="fieldSection">
            <div className="titleSection">
              <div className="left">
                <span className="mainText">Platinum Badge <span style={{color: '#F4AAA9'}}>*</span> </span>
                <span className="subText">(Min 8,000 pts - Max 10,000 Pts)</span>
              </div>
              <div className="right">
                <OptionCheck isSelected={true}>
                  <input className='checkboxInput' type='checkbox' /* value={currentStepRewardsData.defaultRangePoint} */ onChange={(e)=> {setPlatinumSelected(!platinumSelected); e.target.checked? setPlatinumDropdownValue(defaultPlatinum) :setPlatinumDropdownValue({ "value": companyRewardDetails.finished, "label": companyRewardDetails.finished+' points'})}} checked={platinumSelected} />
                </OptionCheck>
                <span className="defaultText">
                  Set Default
                </span>
              </div>
            </div>
            <div className="inputDiv">
              <SearchDropDown
                padding="10px"
                width="145px"
                height="40px"
                indicatorWidth="11px"
                valueContainerP="0px 10px"
                dropdownTextFont="rubik-medium !important"
                transform={ dropdownArrowDirectionPlatinum ? "rotate(0deg)" : "rotate(90deg)"}
                // pcolor={filterValue ? "#005C87" : "#005C87"}
                // pfont={filterValue ? "rubik-medium" : "rubik"}
                right="10px"
                onClick={()=>{
                  setDropdownArrowDirectionPlatinum(!dropdownArrowDirectionPlatinum);
                }}
                onBlur={()=>setDropdownArrowDirectionPlatinum(true)}
                isReportDropdown={false}
                style={{width: "100%"}}
                isReward={true}
              >
                <Select
                  value={platinumDropdownValue? platinumDropdownValue : null}
                  options={platinumData}
                  onChange={(option) => {
                    setPlatinumSelected(false);
                    setPlatinumDropdownValue(option);
                  }}
                  classNamePrefix={"menu-item"}
                  styles={customStyles}
                  components={{ DropdownIndicator: DropdownIndicator, IndicatorSeparator: () => null }}
                  onMenuOpen={this.toggleMenu}
                  onMenuClose={this.toggleMenu}
                  placeholder={"Select points goal"}
                  isSearchable={false}
                  // formatOptionLabel={filterValue}
                />
              </SearchDropDown>
            </div>
          </div>
        </div>
      </div>

      <ButtonContainer 
        isDoneButtonEnabled={
          (!bronzeDropdownValue && !bronzeSelected) || 
          (!silverDropdownValue && !silverSelected) || 
          (!goldDropdownValue && !goldSelected) || 
          (!platinumDropdownValue && !platinumSelected)}
      >
        <button 
          className="doneButton" 
          disabled={ 
            (!bronzeDropdownValue && !bronzeSelected) || 
            (!silverDropdownValue && !silverSelected) || 
            (!goldDropdownValue && !goldSelected) || 
            (!platinumDropdownValue && !platinumSelected) 
          }
          onClick={()=>{
            updateCompanyRewardPoints();
          }}
        >
          Done
          <span>{RewardsPointsManagementDoneButtonIcon()}</span>
        </button>
      </ButtonContainer>
    </RewardsBucketFormMainDiv>
  );
}

ManageRewardsBucketCapsForm.propTypes = {
  setShowManageRewardsBucketCapsForm: PropTypes.func,
  companyRewardDetails: PropTypes.object,
  companyInfo: PropTypes.object,
  history: PropTypes.object,
  hideManageRewardsBucketCapsForm: PropTypes.func
}


export default connect(null, null)(ManageRewardsBucketCapsForm);
